export interface PartialRefundDTO {
  eventId: number;
  reason: RefundReason;
  ticketIds?: number[];
  shopItemIds?: number[];
}

export enum RefundReason {
  Duplicate = 'duplicate',
  Fraudulent = 'fraudulent',
  RequestedByCustomer = 'requested_by_customer',
}

export enum RefundStatus {
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
}

export interface CreateRefundRequestDTO {
  reason: RefundReason;
  description: string;
  items: number[];
}

export interface UpdateRefundRequestDTO {
  reason?: RefundReason;
  description?: string;
  status?: RefundStatus.CANCELLED;
}
