import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Iconify from '../../../components/iconify';
import { useSupabase } from '../../../hooks/use-supabase';

export const isInstagramBrowser = () => {
  if (typeof window === 'undefined') return false;
  return navigator.userAgent.includes('Instagram');
};

interface InstagramBrowserModalProps {
  open: boolean;
  onClose: () => void;
}

const InstagramBrowserModal = ({ open, onClose }: InstagramBrowserModalProps) => {
  const { t } = useTranslation();

  const handleOpenBrowser = () => {
    window.open(window.location.href, '_blank');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: '#000',
          borderRadius: 2,
          mx: 3,
          boxShadow: 'none',
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }}>
        <Typography>{t('instagram.modal.title')}</Typography>
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <Iconify icon="line-md:close" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0, p: 3 }}
      >
        <Iconify icon="bi:phone" width={30} height={30} color="#a1a1a1" />
        <p>{t('instagram.modal.message')}</p>
        <Button variant="contained" fullWidth onClick={handleOpenBrowser} sx={{ mt: 2 }}>
          {t('instagram.modal.button')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default function GoogleLogin() {
  const { supabase } = useSupabase();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    if (isInstagramBrowser()) {
      setOpenModal(true);
    } else {
      onClick();
    }
  };

  let baseUrl: string;

  if (typeof window !== 'undefined') {
    // This code runs only in the browser
    baseUrl = window.location.origin;
  } else {
    // Fallback to a default base URL in case of server-side rendering
    baseUrl = 'http://localhost:8082';
  }
  const returnUrl = window.location.pathname;

  const onClick = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${baseUrl}/auth/callback`,
          queryParams: {
            socialProvider: 'google',
            next: returnUrl, // Add the return URL as a query parameter
            redirectTo: `${baseUrl}/auth/callback`,
          },
        },
      });
    } catch (err) {
      console.error('Unexpected error:', err);
    }
  };
  return (
    <>
      <Button
        fullWidth
        variant="contained"
        style={{
          backgroundColor: '#000000',
          color: '#fff',
        }}
        size="large"
        onClick={handleClick}
        startIcon={<Iconify icon="logos:google-icon" />}
      >
        {t('auth.signInWithGoogle')}
      </Button>
      <InstagramBrowserModal open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
}
